import { createRouter, createWebHistory } from "vue-router";
import { practiceRegistration, patientRegistration, routes } from '@/router/routes';
import { useRouter } from "vue-router";
import { useAuthStore } from '@/stores/auth.store';
import AuthService from '@/services/auth.service';

const host = window.location.host;
const parts = host.split('.');
const domainLength = 3; // route1.example.com => domain length = 3

const finalRoutes = () => {
  let routesArr = [];
  if (parts.length === (domainLength - 1) || parts[0] === 'www') {
    routesArr = routes;
  } else if (parts[0] === 'patient') {
    routesArr = patientRegistration;
  } else if (parts[0] === 'practice') {
    routesArr = practiceRegistration;
  } else {
    // If you want to do something else just comment the line below
    routesArr = routes;
  }
  return routesArr;
};


const router = createRouter({
  history: createWebHistory(),
  routes: finalRoutes(),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

const title = "Heron Copay";

router.beforeEach((to, from, next) => {
  const router = useRouter();

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title + " | " + title || title;
  }

  if (!to.meta.authRequired) {
    return next();
  }
  const authStore = useAuthStore();
  const loggedIn = authStore.isAuthenticated;
  const token = AuthService.getToken();

  if (loggedIn && token) {
    next();
  } else {
    router.push("/signin");
  }
});

export default router;
