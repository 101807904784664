// Desc: HTTP service for making API calls
import axios from 'axios';
import type { HeadersDefaults } from 'axios';
import { API_URL } from '@/configs';
import AuthService from '@/services/auth.service';
import { deserialize } from "deserialize-json-api";
import router from '@/router';

const http = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.response.use(
  (response) => {
    return {
      ...response,
      ...deserialize(response.data, { transformKeys: "camelCase" }),
    };
  },
  (error) => {
    if (error.request.status === 401) {
      AuthService.clearCache();
      router.push("/signin");
    }

    if (error.request.status === 404) {
      router.push("/404");
    }

    if (error.request.status === 500) {
      router.push("/500");
    }

    const errMessage: string = error?.response?.data || 'Unknown Error';
    return Promise.reject(errMessage);
  },
);

http.defaults.headers.common['Authorization'] = localStorage.getItem('token');

const setHTTPHeader = (header: HeadersDefaults['common']) => {
  http.defaults.headers.common = { ...http.defaults.headers.common, ...header };
};

export { http, setHTTPHeader };
