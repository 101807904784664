import {
  MenuItemType,
  CartItemType,
  NotificationType,
} from "@/components/layouts/types";

export const menuItems: MenuItemType[] = [
  {
    label: "patients",
    icon: "ph-user-circle",
    link: "/patients",
    id: "patients",
  },
  {
    label: "practices",
    icon: "ph-first-aid",
    link: "/practices",
    id: "practices",
  },
  {
    label: "claims",
    icon: "ph-clipboard",
    link: "/claims",
    id: "claims",
  },
];

export const cartItems: CartItemType[] = [
  // {
  //   id: 1,
  //   src: Img1,
  //   subTitle: "Fashion",
  //   title: "Blive Printed Men Round Neck",
  //   price: 327.49,
  //   items: 2,
  // },
  // {
  //   id: 2,
  //   src: Img5,
  //   subTitle: "Sportwear",
  //   title: "Willage Volleyball Ball",
  //   price: 49.06,
  //   items: 3,
  // },
  // {
  //   id: 3,
  //   src: Img10,
  //   subTitle: "Fashion",
  //   title: "Cotton collar t-shirts for men",
  //   price: 53.33,
  //   items: 3,
  // },
];

export const notifications: NotificationType[] = [
  // {
  //   isSelected: false,
  //   id: "unread-1",
  //   src: Avatar2,
  //   title: "Angela Bernier",
  //   message: "Answered to your comment on the cash flow forecast's graph 🔔.",
  //   time: "48 min ago",
  //   isRead: false,
  // },
  // {
  //   isSelected: false,
  //   id: "unread-2",
  //   icon: "bx bx-badge-check bx-xs",
  //   message: `<h4>Your <b>Elite</b> author Graphic
  //   Optimization <span class="text-secondary">reward</span> is ready!</h4>`,
  //   time: "Just 30 sec ago",
  //   isRead: false,
  // },
  // {
  //   isSelected: false,
  //   id: "unread-3",
  //   icon: "bx bx-message-square-dots",
  //   message: `<h4>You have received <b class="text-success">20</b> new messages in the conversation</h4>`,
  //   time: "2 hrs ago",
  //   isRead: false,
  // },
  //
  // {
  //   isSelected: false,
  //   id: "read-1",
  //   src: Avatar8,
  //   title: "Maureen Gibson",
  //   message: "We talked about a project on linkedin.",
  //   time: "4 hrs ago",
  //   isRead: true,
  // },
];
