<script lang="ts" setup>

const href = () => {
  if (window.location.origin.includes('patient')) {
    return '/Insurance-Verification-and-Program-Enrollment-Form.pdf';
  } else if (window.location.origin.includes('practice')) {
    return '/Practice-Copay-Assist-Program-Enroll-Form.pdf';
  }
}
const file = () => {
  if (window.location.origin.includes('patient')) {
    return 'Insurance-Verification-and-Program-Enrollment-Form.pdf';
  } else if (window.location.origin.includes('practice')) {
    return 'Practice-Copay-Assist-Program-Enroll-Form.pdf';
  }
};

</script>
<template>
  <v-container class="bg-white max-width-850 mb-5">
    <div class="blue-line"></div>
    <v-row class="d-flex align-center">
      <v-col cols="4">
        <img src="@/assets/images/logo-light.svg" alt="" height="80" />
      </v-col>

      <v-col class="text-right mr-10" cols="7">
        <v-row class="justify-end">
          <span>
            If you prefer to fax in your application, click below for the fax form
          </span>
        </v-row>

        <v-row class="justify-end">
          <v-btn
            color="#0075BC"
            dark
            :href="href()"
            :download="file()"
          >
            Download the fax form
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
  <router-view />
</template>

<style>
.max-width-850 {
  max-width: 850px;
  padding: 0;
  margin: 0 0 10px;
  border-radius: 15px;
}
.blue-line {
  height: 10px;
  background-color: #0075BC !important;; /* Change this to the desired blue color */
  width: 100%; /* Full width */
}
</style>
