import { DefaultLayout, AuthLayout, Registration } from "@/layouts/index";

const pagesPrefix = "/pages";

const accountRoutes = [
  {
    path: `/signin`,
    name: "AccountSignIn",
    component: () => import("@/views/account/SignIn.vue"),
    meta: { title: "Sign In", authRequired: false },
  },
].map((data) => {
  return {
    ...data,
    meta: { ...data.meta, layout: AuthLayout },
  };
});

const registrationRoutes = [
  {
    path: `/practice_registration`,
    name: "PracticeRegistration",
    component: () => import("@/views/registration/practiceRegistration.vue"),
    meta: { title: "Practice Enrollment", authRequired: false },
  },
  {
    path: `/patient_registration`,
    name: "PatientRegistration",
    component: () => import("@/views/registration/patientRegistration.vue"),
    meta: { title: "Patient Enrollment", authRequired: false },
  },
].map((data) => {
  return {
    ...data,
    meta: { ...data.meta, layout: Registration },
  };
});

const pacientRegistrationRoutes = [
  {
    path: `/`,
    name: "PatientRegistration",
    component: () => import("@/views/registration/patientRegistration.vue"),
    meta: { title: "Patient Enrollment", authRequired: false },
  },
].map((data) => {
  return {
    ...data,
    meta: { ...data.meta, layout: Registration },
  };
});

const practiceRegistrationRoutes = [
  {
    path: `/`,
    name: "PracticeRegistration",
    component: () => import("@/views/registration/practiceRegistration.vue"),
    meta: { title: "Practice Enrollment", authRequired: false },
  },
].map((data) => {
  return {
    ...data,
    meta: { ...data.meta, layout: Registration },
  };
});

const dashboardRoutes = [
  {
    path: "/",
    name: "RootPage",
    component: () => import("@/views/patients/List.vue"),
    meta: { title: "Patients", authRequired: true, layout: DefaultLayout },
  },
  {
    path: "/patients",
    name: "PatientsList",
    component: () => import("@/views/patients/List.vue"),
    meta: { title: "Patients", authRequired: true, layout: DefaultLayout },
  },
  {
    path: "/patients/:id",
    name: "Patient",
    component: () => import("@/views/patients/Show.vue"),
    meta: { title: "Patient", authRequired: true, layout: DefaultLayout },
  },
  {
    path: "/patients/:id/edit",
    name: "EditPatient",
    component: () => import("@/views/patients/Edit.vue"),
    meta: { title: "Edit Patient", authRequired: true, layout: DefaultLayout },
  },
  {
    path: "/patients/create",
    name: "NewPatient",
    component: () => import("@/views/patients/Create.vue"),
    meta: { title: "New Patient", authRequired: true, layout: DefaultLayout },
  },
  {
    path: "/practices",
    name: "PracticesList",
    component: () => import("@/views/practices/List.vue"),
    meta: { title: "Practices", authRequired: true, layout: DefaultLayout },
  },
  {
    path: "/practices/:id",
    name: "ShowPractice",
    component: () => import("@/views/practices/Show.vue"),
    meta: { title: "Practice", authRequired: true, layout: DefaultLayout },
  },
  {
    path: "/practices/:id/edit",
    name: "EditPractice",
    component: () => import("@/views/practices/Edit.vue"),
    meta: { title: "Edit Practice", authRequired: true, layout: DefaultLayout },
  },
  {
    path: "/practices/create",
    name: "NewPractice",
    component: () => import("@/views/practices/Create.vue"),
    meta: { title: "New Practice", authRequired: true, layout: DefaultLayout },
  },
  {
    path: "/claims",
    name: "ClaimsList",
    component: () => import("@/views/claims/List.vue"),
    meta: { title: "Claims", authRequired: true, layout: DefaultLayout },
  },
  {
    path: "/claims/:id",
    name: "Claim",
    component: () => import("@/views/claims/Show.vue"),
    meta: { title: "Claim", authRequired: true, layout: DefaultLayout },
  },
  {
    path: "/claims/:id/edit",
    name: "EditClaim",
    component: () => import("@/views/claims/Edit.vue"),
    meta: { title: "Edit Claim", authRequired: true, layout: DefaultLayout },
  },
  {
    path: "/claims/create",
    name: "New",
    component: () => import("@/views/claims/Create.vue"),
    meta: { title: "New Claim", authRequired: true, layout: DefaultLayout },
  },
].map((data) => {
  return {
    ...data,
    meta: { ...data.meta, layout: DefaultLayout },
  };
});

const authRoutes = [
  {
    path: `/lockscreen`,
    name: "LockScreen",
    component: () => import("@/views/authentication/LockScreen.vue"),
    meta: { title: "Lock Screen", authRequired: false },
  },
  {
    path: `/success-msg`,
    name: "SuccessMessage",
    component: () => import("@/views/authentication/SuccessMsg.vue"),
    meta: { title: "Success Message", authRequired: false },
  },
  {
    path: "/:pathMatch(.*)*", // https://next.router.vuejs.org/guide/migration/#removed-star-or-catch-all-routes
    name: "Error404",
    component: () => import("@/views/authentication/error/404.vue"),
    meta: { title: "404 Error", authRequired: false },
  },
  {
    path: `/500`,
    name: "Error500",
    component: () => import("@/views/authentication/error/500.vue"),
    meta: { title: "500 Error", authRequired: false },
  },
  {
    path: `/reset-password`,
    name: "ResetPassword",
    component: () => import("@/views/authentication/ResetPassword.vue"),
    meta: { title: "Reset Password", authRequired: false },
  },
  {
    path: `/reset_password/:token`,
    name: "AccountChangePassword",
    component: () => import("@/views/authentication/CreatePassword.vue"),
    meta: { title: "Create New Password", authRequired: false },
  },
  {
    path: `/503`,
    name: "Error503",
    component: () => import("@/views/authentication/error/503.vue"),
    meta: { title: "503 Error", authRequired: false },
  },
].map((data) => {
  return {
    ...data,
    meta: { ...data.meta, layout: AuthLayout },
  };
});
const pagesRoutes = [
  {
    path: `${pagesPrefix}/profile-settings`,
    name: "PagesProfileSettings",
    component: () => import("@/views/pages/ProfileSettings.vue"),
    meta: {
      title: "Profile Settings",
      authRequired: false,
      layout: DefaultLayout,
    },
  },
  {
    path: `${pagesPrefix}/faqs`,
    name: "PagesFAQs",
    component: () => import("@/views/pages/FAQs.vue"),
    meta: { title: "FAQs", authRequired: false, layout: DefaultLayout },
  },
  {
    path: `${pagesPrefix}/maintenance`,
    name: "PagesMaintenance",
    component: () => import("@/views/pages/Maintenance.vue"),
    meta: { title: "Maintenance", authRequired: false, layout: AuthLayout },
  },
  {
    path: `${pagesPrefix}/coming-soon`,
    name: "PagesComingSoon",
    component: () => import("@/views/pages/ComingSoon.vue"),
    meta: { title: "Coming Soon", authRequired: false, layout: AuthLayout },
  },
];

export const routes = [
  ...accountRoutes,
  ...dashboardRoutes,
  ...authRoutes,
  ...pagesRoutes,
  ...registrationRoutes,
];

export const practiceRegistration = [...practiceRegistrationRoutes];
export const patientRegistration = [...pacientRegistrationRoutes];
