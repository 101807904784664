<template>
  <v-card class="text-center auth-card" >
    
    <img src="images/powered.png" alt="powered">
<!--    <v-card-title class="mt-10">-->
<!--    </v-card-title>-->
<!--    <v-card-text>-->
<!--      <div class="auth-effect-main rounded-circle mx-auto my-10">-->
<!--        <div class="effect-circle-1 rounded-circle">-->
<!--          <div class="effect-circle-2 rounded-circle">-->
<!--            <div class="effect-circle-3 rounded-circle text-white">-->
<!--              <span class="text-primary text-h6">Heron Connect Copay Portal</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </v-card-text>-->
<!--    <v-card-actions class="pb-10 justify-center auth-banner-action">-->
<!--      <p class="text-white opacity-75 text-caption font-weight-regular">-->
<!--        &copy; {{ new Date().getFullYear() }} HarborPath.-->
<!--      </p>-->
<!--    </v-card-actions>-->
  </v-card>
</template>
