import type { AxiosResponse } from 'axios';
import { http } from '@/services/http.service';
import { IRegisterUser, IUserLogin } from '@/types/general';

export const login = (user: IUserLogin): Promise<AxiosResponse> => {
  return http.post('/api/v1/users/login', { user });
};

export const logout = (): Promise<AxiosResponse> => {
  return http.delete('/api/v1/users/logout');
};

export const register = (user: IRegisterUser): Promise<AxiosResponse> => {
  return http.post('/users/signup', { user });
};

export const forgotPassword = (email: string): Promise<AxiosResponse> => {
  return http.post('/api/v1/users/password', { user: { email } });
};

export const resetPassword = (password: string, password_confirmation: string, token: string): Promise<AxiosResponse> => {
  return http.patch('/api/v1/users/password', { user: { password, password_confirmation, reset_password_token: token }});
};
